import React, { Fragment, useState, forwardRef, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import DatePicker from "react-datepicker";
import Layout from "../components/Layout";
import Hero from "../components/hero/HeroNewsletter";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";
// import { SvgBar2 } from "../components/svg";
import { truncateString, removeDateFromString } from "../utils/common";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/newsletter.scss";

const Newsletter = ({
  data: { sanityNewsletterPage, allSanityArticle, sanitySite },
}) => {
  const { nodes } = allSanityArticle;
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [list, setList] = useState(nodes);

  //   const removeDate = (str) => {
  //     const colonIndex = str.indexOf(":");
  //     if (colonIndex !== -1) {
  //       return str.substring(colonIndex + 1);
  //     }
  //     return str;
  //   };
  const CustomInput = forwardRef(({ value, onClick, label }, ref) => (
    <div
      className="border relative border-[#CECECE] justify-between rounded-xl flex py-[10px] px-3 cursor-pointer"
      onClick={onClick}
      ref={ref}
    >
      <p className="px-3 text-base font-normal uppercase">{label}</p>
      <div className="flex items-center space-x-3">
        <p className={`${value ? "" : "px-10"}`}>{value}</p>
        <div className="flex items-center space-x-3">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0V2H13V0H15V2H19C19.5523 2 20 2.44772 20 3V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V3C0 2.44772 0.44772 2 1 2H5V0H7ZM18 10H2V18H18V10ZM5 4H2V8H18V4H15V6H13V4H7V6H5V4Z"
              fill="black"
            />
          </svg>
          <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1L4 4L1 1"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    if (fromDate && toDate) {
      const fd = new Date(fromDate).getTime();
      const td = new Date(toDate).getTime();
      const filterList = nodes.filter((item) => {
        const s = new Date(item._createdAt).getTime();

        return s >= fd && s <= td;
      });
      setList(filterList);
    } else {
      setList(nodes);
    }
  }, [fromDate, toDate]);

  const clearFilter = () => {
    setFromDate(null);
    setToDate(null);
  };

  return (
    <Fragment>
      <Layout headerData={sanitySite?.header}>
        <Hero data={sanityNewsletterPage.heroSection} />
        <div className="container flex flex-col pt-11 md:flex-row md:justify-between md:items-center">
          <h3 className="mb-2 text-2xl font-normal md:mb-0">Recent Editions</h3>

          <div className="flex flex-col sm:items-center sm:flex-row">
            {fromDate && toDate && (
              <span
                className="px-4 text-sm cursor-pointer"
                onClick={clearFilter}
              >
                Clear
              </span>
            )}
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              customInput={<CustomInput label="From" />}
              key={"from"}
            />
            &nbsp; &nbsp;
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              customInput={<CustomInput label="To" />}
              key={"to"}
            />
          </div>
        </div>
        <section
          className={
            "container mx-auto pt-7 pb-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-7 xl:gap-x-11 gap-y-5 md:gap-y-[35px]"
          }
        >
          {list &&
            list.map((article, i) => {
              return (
                <Link
                  to={`/${article.slug.current}`}
                  key={i}
                  className="duration-500 transistion-all hover:bg-slate-100 hover:shadow-xl"
                >
                  {/* <div className="flex flex-col md:flex-row bg-[url('../images/newsletter-bg.png')] bg-cover py-3 md:h-[187px]"> */}
                  <div>
                    <div className="rounded-md overflow-hidden sm:h-[200px]">
                      {article?.featuredImage ? (
                        <GatsbyImage
                          className="object-cover w-full h-full rounded-md"
                          image={article?.featuredImage?.asset?.gatsbyImageData}
                          alt={article?.heading}
                        />
                      ) : (
                        <StaticImage
                          src="../../images/placeholder.webp"
                          alt="placeholder"
                          className="object-cover w-full h-full rounded-md"
                        />
                      )}
                    </div>
                    <div className="px-2 py-2">
                      <p className={"font-lora font-bold text-lg"}>
                        {removeDateFromString(article.heading)}
                      </p>
                      {/* <SvgBar2 /> */}
                      <p className="pt-1 text-sm font-normal font-ssp lg:text-base">
                        {article?.summary &&
                          truncateString(article.summary, 200)}
                      </p>
                      {/* <Link
                      to={`/${article.slug.current}`}
                      className="relative text-[13px] mt-3 font-robot w-[157px] h-[40px] flex items-center justify-center"
                    >
                      EXPLORE
                      <div className="absolute top-0 bottom-0 left-0 right-0 -z-10">
                        <StaticImage
                          src="../images/btn-border.svg"
                          alt="btn-border"
                          placeholder="blurred"
                          className="w-full h-full"
                          objectFit="contain"
                        />
                      </div>
                    </Link> */}
                    </div>
                  </div>
                </Link>
              );
            })}
        </section>
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default Newsletter;
export const Head = ({ data: { sanityNewsletterPage } }) => (
  <SEO metadata={sanityNewsletterPage?.pageMetadata || {}} />
);
export const query = graphql`
  query {
    sanityNewsletterPage {
      title
      id
      heroSection {
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        title
        caption
      }

      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityArticle(
      filter: {
        category: {
          elemMatch: { _id: { eq: "23215274-fa1d-46f7-8e91-041a0ff80ea8" } }
        }
      }
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        templateType
        _createdAt
        featuredImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
          }
        }
        summary
        slug {
          current
        }
        id
        heading
      }
    }
    sanitySite {
      header {
        links {
          label
          path
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
      }
    }
  }
`;
